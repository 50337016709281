import { XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

export default function SystemMessageBanner() {
  const messageText = process.env.NEXT_PUBLIC_TOP_MESSAGE_BANNER_TEXT ?? '';

  const [bannerShown, setBannerShown] = useState(!!messageText);

  function dismiss() {
    setBannerShown(false);
  }

  return bannerShown ? (
    <div className="fixed top-0 opacity-[0.95] z-[80] w-full text-white text-md 2xl:text-lg flex justify-center text-center items-center p-4 bg-bobo-orange">
      <span>{messageText}</span>
      <div className="inline-block">
        <button
          onClick={dismiss}
          className="flex top-2 right-2 transition-color duration-300 rounded-full shadow-xl p-2 z-40 bg-dark-morefaded hover:bg-dark-faded">
          <XMarkIcon className="h-6 w-6 cursor-pointer" />
        </button>
      </div>
    </div>
  ) : null;
}
